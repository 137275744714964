import * as Yup from 'yup';
import 'yup-phone';

import { FormGeneratorInitialValues, TFormFieldsFactoryProps } from '../models/form-field.model';
import createConsents from '../utils/create-consents';

export const userDataForm = ({
    consents = [],
    t,
}: TFormFieldsFactoryProps<'UserDataForm', { consents: (string | null)[] }>) => {
    return [
        {
            name: 'firstName',
            type: 'text',
            label: t.firstName.label,
            initialValue: '',
            schema: Yup.string().required(t.firstName.error.required),
        },
        {
            name: 'lastName',
            type: 'text',
            label: t.lastName.label,
            initialValue: '',
            schema: Yup.string().required(t.lastName.error.required),
        },
        {
            name: 'email',
            type: 'email',
            label: t.email.label,
            initialValue: '',
            schema: Yup.string().email(t.email.error.email).required(t.email.error.required),
            settings: {
                disabled: true,
            },
        },
        ...createConsents(consents, t.agreement.error.required, () => ({ disabled: true })),
        {
            name: 'save',
            type: 'submit',
            label: t.save.label,
        },
    ] as const;
};

export type TUserDataInitialValues = FormGeneratorInitialValues<
    ReturnType<typeof userDataForm>,
    string
>;

import React, { useState } from 'react';

import {
    wrapper,
    title as titleClass,
    titleInputs,
    titleCheckboxes,
    form,
    formContainer,
    successOutput,
} from './user-data.module.scss';

import { userDataForm } from '../../formik/user-data.form';
import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import { IProfile } from '../../models/profile.model';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import useTranslations from '../../hooks/use-translations';
import { useProfile } from '../../hooks/use-profile';

import FormGenerator from '../molecules/form-generator';
import UserLayout from '../../layouts/user-layout';

export interface IUserDataSection extends ISection {
    items: {
        links: INavLink[];
    };
}

export interface IUserDataProps {
    className?: string;
    section: IUserDataSection;
}

const UserData = ({ section, className }: IUserDataProps) => {
    const t = useTranslations('UserData');
    const { sectionId, css, style } = section;
    const [formTitle, consentTitle, ...consents] = section.content.texts;
    const { links } = section.items;

    const profile = useProfile();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(profile.update.errors);
    const [visibleSuccessOutput, setVisibleSuccessOutput] = useState(false);

    const handleSubmit = async (values: any) => {
        try {
            await profile.update.fetch(values);
            setVisibleSuccessOutput(true);

            setTimeout(() => {
                setVisibleSuccessOutput(false);
            }, 3000);
        } catch {}
    };

    return (
        <UserLayout
            sectionId={sectionId}
            childrenWrapperClassName={wrapper}
            className={className}
            links={links}
            css={css}
            style={style}
        >
            <FormGenerator
                name="UserDataForm"
                formRef={formikRef}
                className={formContainer}
                formClassName={form}
                formikProps={{
                    onSubmit: handleSubmit,
                    initialValues: transformUserData(profile.data),
                    enableReinitialize: true,
                }}
                fields={userDataForm}
                extraFieldsProps={{ consents }}
                errorMessage={globalErrorMessage}
                submitLoading={profile.update.isLoading || profile.get.isFetching}
                submitOnlyOnChange={true}
            >
                <p className={`${titleClass} ${titleInputs}`}>{formTitle}</p>
                <p className={`${titleClass} ${titleCheckboxes}`}>{consentTitle}</p>
                {visibleSuccessOutput && <output className={successOutput}>{t.success}</output>}
            </FormGenerator>
        </UserLayout>
    );
};

function transformUserData(profile?: IProfile | null) {
    return {
        firstName: profile?.firstName ?? '',
        lastName: profile?.lastName ?? '',
        email: profile?.email ?? '',
        phone: profile?.phone ?? '',
        agreement0: true,
    };
}

export default UserData;
